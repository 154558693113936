import { IPatient } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { UseMutationOptions, useMutation } from 'react-query'

type MutationOptions = Omit<
UseMutationOptions<void, unknown, void, unknown>,
'mutationKey' | 'mutationFn'
>;

function useRestoreDefaultCoverage(uid: string, mutationOptions?: MutationOptions) {
	return useMutation(
		['Megalith-Query','restoreDefaultCoverage'],
		async () => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${token}`,
				uid: uid /** Este siempre es el del usuario principal */,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
			}

			await axios.post<IPatient[]>(
				`${UMA_BACKEND_URL}/coverages/register/default`,
				{
					uid: uid,
				},
				{ headers }
			)

		},
		mutationOptions
	)
}

export default useRestoreDefaultCoverage
