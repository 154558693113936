import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import {
	make_specialist_appointment,
	make_specialist_appointment_v2,
	make_onsite_appointment
} from '@/config/endpoints'
import moment from 'moment'
import { specialistPaymentMethods } from './usePaymentData'
import { errorHandler } from '@/config/ErrorBoundary'
import { usePostHog } from 'posthog-js/react'
import { checkUtmParams } from '@/components/Utils/checkUtmParams'
import { countries } from '@umahealth/entities'
import { revalidatePatientServer } from '@/app/services/serverServices/getPatientServer'

interface IPaymentData {
	id: string
	method: 'mercadopago' | 'Subscription' | 'OSS' | 'UmaCreditos' | 'Corporate'
	price: number
	trueMethod: specialistPaymentMethods
	initialPrice?: number
}

interface IPaymentData__Consultorios {
	method: 'consultorio'
	practices: 'especialista'
	trueMethod: specialistPaymentMethods
	// falta plan
}

interface IPracticeData {
	cod: string
	name: string
	plan: string
	price: string
}

interface IGeo {
	lat: number | null
	lng: number | null
}

export type PaymentData = IPaymentData | IPaymentData__Consultorios

export interface IMakeSpecialistAppointment {
	type: 'online' | 'consultorio'
	assignation_id: string
	attentionDate: string // TO DO: legacy. borrar
	attentionTime: string // TO DO: legacy. borrar
	dependantUid: string | false
	doctorUid: string
	especialidad: string
	motivosDeConsulta?: string
	uid: string
	practices?: IPracticeData
	geo: IGeo
	country?: countries
}

/**
 *
 * Hace un turno de especialista
 */

const makeSpecialistAppointmentUrls = {
	'MX': make_specialist_appointment_v2,
	'VE': make_onsite_appointment,
	'AR': make_specialist_appointment
}

function useMakeSpecialistAppointment({
	assignation_id,
	uid,
	dependantUid,
	doctorUid,
	type,
	attentionDate,
	attentionTime,
	especialidad,
	motivosDeConsulta,
	practices,
	geo,
	country
}: IMakeSpecialistAppointment) {
	const queryClient = useQueryClient()
	const posthog = usePostHog()

	const mutator = useMutation(
		['UmaNodeServer-Query', 'makeSpecialistAppointment'],
		async (paymentData: PaymentData) => {
			let makeSpecialistAppointmentUrl = makeSpecialistAppointmentUrls[process.env.NEXT_PUBLIC_COUNTRY]

			if (type === 'consultorio') {
				makeSpecialistAppointmentUrl = make_onsite_appointment
			}

			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				if (errorHandler) errorHandler.report(err as Error)
				throw new Error(`${err}`)
			}

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${token}`,
			}

			const data = {
				assignation_id: assignation_id,
				dependantUid: dependantUid,
				doctorUid: doctorUid,
				uid: uid,
				assignation_type: type,
				attentionDate: attentionDate,
				attentionTime: attentionTime,
				country: country || process.env.NEXT_PUBLIC_COUNTRY,
				especialidad: especialidad,
				event_type: type === 'consultorio' ? 'onsite' : 'online',
				isdependant: dependantUid ? true : false,
				geo,
				motivo_de_consulta: motivosDeConsulta,
				motivos_de_consulta: motivosDeConsulta,
				payment_data: paymentData,
				practices: [practices],
				action: {
					action: 'assign',
					by: 'Autogestión',
					date: moment().format('YYYY/MM/DD HH:mm'),
				},
				plan: undefined, // Va a ser paymentData.plan pero despues
			}

			return await axios.post(makeSpecialistAppointmentUrl, data, {
				headers,
			})
		},
		{
			onSuccess: async () => {
				checkUtmParams(posthog, uid, 'especialista', assignation_id)
				queryClient.invalidateQueries([
					'activeAppointment',
					assignation_id,
					type,
				])

				await revalidatePatientServer()
			}
		},
	)

	return mutator
}

export default useMakeSpecialistAppointment
