import axios from 'axios'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { refundsMethods } from '@/config/endpoints'
import { QueryKey, UseQueryOptions, useQuery } from 'react-query'

export type refundMethods = 'UmaCreditos' | 'MercadoPago' | 'Subscription'
type queryOptions = Omit<UseQueryOptions<refundMethods[], unknown, refundMethods[], QueryKey>, 'queryKey' | 'queryFn'>

/**
 * 
 * @returns Devuelve los métodos que tenga como refund el usuario, si el usuario tiene una
 * OSS que cubre la guardia, devuelve ```'Ninguna'```, si el usuario está subscripto y se le debe
 * devolver la consulta, devuelve ```Suscripcion```, en otros casos difiere entre ```Mercadopago``` y ```UmaCreditos```
 */
function useRefundMethods(assignation_id: string, options?: queryOptions) {
	let firebaseToken : string
	const refundFn = async() => {
		firebaseToken = await getFirebaseIdToken()

		const headers = { 
			'Authorization': `Bearer ${firebaseToken}`,
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}

		const result = await axios.get<Array<refundMethods>>(
			`${refundsMethods}/${assignation_id}`,
			{ 
				headers,
				timeout: 2000 
			},
		).catch(err => {
			console.log(err)
			return { data: [] }
		})

		return result.data
	}

	return useQuery<refundMethods[]>({
		queryKey: ['Megalith-Query', 'RefundMethods'], 
		queryFn: async () => await refundFn(),
		...options,
	})
	
}

export default useRefundMethods