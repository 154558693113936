import axios from 'axios'
import { get_prices } from '@/config/endpoints'
import { useQuery, UseQueryOptions } from 'react-query'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'

export interface IPracticesInfo {
	cod: string,
	currency: string,
	id: string,
	name: string,
	conditions:string,
	plan: string,
	practiceId: string,
	price: string,
	vigence: {
		from: string,
		to: string
	}
}

interface IUsePractices {
	specialty: string,
	uid: string,
	dependantUid?: string
}

type QueryOptions = Omit<UseQueryOptions<IPracticesInfo[], unknown, IPracticesInfo[], string[]>, 'queryKey' | 'queryFn'>

function usePractices({
	specialty,
	uid,
	dependantUid
} : IUsePractices, options? : QueryOptions) {

	const path = `${get_prices}/${specialty}/${dependantUid || uid}`

	return useQuery({
		queryKey: ['Megalith-Query','practices', specialty, uid], 
		queryFn: async () => {
			const token = await getFirebaseIdToken()
			const headers = {
				Authorization: `Bearer ${token}`,
				uid,
			}
			const practices = await axios.get<IPracticesInfo[]>(path, { headers })
			return practices.data
		},
		...options
	})
}

export default usePractices