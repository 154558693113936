import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { errorHandler } from '@/config/ErrorBoundary'
import { TRIAGE_UMA } from '@/config/endpoints'
import { useMutation } from 'react-query'

interface IPatientInfo {
	ws?: string
	dni?: string,
	sex?: string,
	dob?: string,
	lat: '',
	lon: '',
	uid: string,
	dependant_uid: false|string,
}

async function getFirstQuestions(patientInfo : IPatientInfo, selectedTags : string[], inputAnswer : string) : Promise<string[][]>{

	let token

	try {
		token = await getFirebaseIdToken()
	} catch (err) {
		if(errorHandler) errorHandler.report(err as Error)
		throw new Error(`${err}`)
	}

	const headers = {
		'Content-Type': 'application/json;charset=UTF-8',
		'Authorization': `Bearer ${token}`,
	}
	const autonomousUrl = `${TRIAGE_UMA}/autonomous`


	const response = await axios
		.post(
			autonomousUrl,
			{
				ws: patientInfo.ws || '',
				dni: patientInfo,
				sex: patientInfo.sex || '',
				dob: patientInfo.dob || '',
				uid: patientInfo.uid,
				uid_dependant: patientInfo.dependant_uid,
				inputAnswer: inputAnswer,
				selectedTags: selectedTags,
				lat: '',
				lon: '',
			},
			{
				headers: headers,
			}
		)

	return response.data
}

/**
 * Retorna la lista de obras sociales posibles de acuerdo a un input
 * @returns Array con index, lavel y value para las distintas obras sociales
 */
function useGetFirstQuestions (patientInfo : IPatientInfo) {
	return useMutation(['UmaNodeServer-Query', 'firstQuestions'],async ({
		inputAnswer,
		selectedTags,
	} : {
		inputAnswer: string,
		selectedTags: string[]
	}) => {
		return getFirstQuestions(patientInfo, selectedTags, inputAnswer)
	})
}

export default useGetFirstQuestions
