import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { useMutation } from 'react-query'
import { ICoverageData } from './ICoverageData'
import { UMA_BACKEND_URL } from '@/config/endpoints'

export interface IUpdateCoverage {
	coverage: ICoverageData
	uid:string
	dependantUid: string|undefined
}

/**
 * RegisterPatientCorporate registra un nuevo paciente.
 * @param uid uid del usuario principal para hacer la llamada al back
 * @param corporateInfo Datos necesarios para registrar la nueva obra social
 * Si la obra social ya existe dara error
 */
async function updatePatientCorporate( uid: string, dependantUid:string|undefined, coverageInfo: ICoverageData) {
	const firebaseToken = await getFirebaseIdToken()

	const path = dependantUid ? `${UMA_BACKEND_URL}/coverages/dependant/update` : `${UMA_BACKEND_URL}/coverages/update`

	const headers = { 
		'Content-Type': 'Application/Json', 
		'Authorization': `Bearer ${firebaseToken}` ,
		'uid': uid, /** Este siempre es el del usuario principal */
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
	}
	const bodyRequest = {
		uid: dependantUid??uid,
		corporateId: coverageInfo.name,
		data: {
			affiliate_id: coverageInfo.affiliateId,
			plan: coverageInfo.plan,
			id: coverageInfo.name,
			userInput: coverageInfo.name,
			credentialVersion: coverageInfo.credentialVersion || null,
		}
	}

	await axios.patch(path, bodyRequest, { headers })
}

/**
 * useUpdateCoverage registra un nueva coverage
 */
function useUpdateCoverage() {
	return useMutation(['Megalith-Query','updatePatientCoverage'], async(corporateInfo : IUpdateCoverage) => {
		return updatePatientCorporate(corporateInfo.uid, corporateInfo.dependantUid, corporateInfo.coverage)
	})
}

export default useUpdateCoverage