import axios from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { UMA_BACKEND_URL } from '@/config/endpoints'

type Queryoptions = Omit<
UseQueryOptions<ValidateResponse[], unknown, ValidateResponse|undefined, string[]>,
'queryKey' | 'queryFn'
>;

interface ValidateResponse {
	'corporate': string,
	'valid': boolean,
	'primary': boolean,
	'plan': string
}

/** Valida las coverages de los usuarios */
function useValidateCoverage(uid: string, queryOptions: Queryoptions) {
	return useQuery(
		['Megalith-Query','validateCoverage', uid],
		async () => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${token}`,
				uid: uid /** Este siempre es el del usuario principal */,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
			}

			const response = await axios.get<ValidateResponse[]>(
				`${UMA_BACKEND_URL}/coverages/validate/${uid}`,
				{ headers }
			)

			return response.data
		},
		queryOptions
	)
}

export default useValidateCoverage
