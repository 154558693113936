import { activate_subscription } from '@/config/endpoints'
import { useMutation } from 'react-query'

interface IActiveSubscriptionMutation {
	documentId: string | null
	mpPlanId: string | null
	preapprovalId: string | string[] | null
}

function useActivateSubscription({ uid, token }: { uid: string, token: string }) {
	return useMutation(
		['Megalith-Query', 'useCreatePendingSubscription'],
		async (activeSubscriptionMutation: IActiveSubscriptionMutation) => {
			try {
				if (!token) {
					throw new Error('Not a valid token')
				}
				
				if (!uid) {
					throw new Error('Not a valid uid')
				}

				const response = await fetch(activate_subscription, {
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
						uid: uid,
					},
					body: JSON.stringify(activeSubscriptionMutation),
				})

				if (!response.ok) {
					throw new Error('Error validating subscription')
				}

				const data = await response.json()
				return data
			} catch (error) {
				throw new Error(`Error in useActivateSubscription: ${error}`)
			}
		},
	)
}

export default useActivateSubscription
