import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosResponse } from 'axios'
import { order_pdf } from '@/config/endpoints'
import { UseMutationResult, useMutation } from 'react-query'

// TODO: any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OrderMutator = UseMutationResult<AxiosResponse<any>, unknown, string, unknown>

/**
 * Sirve para generar un PDF de la orden médica
 * @param country country
 * @returns 
 */
function useOrderPDF(country: string){
	let firebaseToken : string

	const mutator = useMutation(['UmaNodeServer-Query', 'orderPDF'],async(id: string)=>{
		
		firebaseToken = await getFirebaseIdToken()
    
		const headers = {
			'Content-Type': 'Application/Json',
			Authorization: `Bearer ${firebaseToken}`,
		}
    
		return axios.post(
			order_pdf,
			{ 
				id: id,
				country: country,
				/** usa el PDF que ya se encuentra generado, true vuelve a generar un pdf nuevo y lo retorna */
				rewrite: true
			},
			{ 
				headers
			}
		)
	}, {
		retry: 2,
	})

	return mutator
	
}

export default useOrderPDF