import { requestTypes } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import {feedbackCSAT} from '@/config/endpoints'
import axios from 'axios'


import { useMutation } from 'react-query'

export interface IEvaluateDataCSAT {
	assignationId: string | undefined,
	uid: string,
	umaEval: string,
	docEval: string,
	comments: string,
	selectedTags: string[],
	type?: requestTypes,
}

/** Permite evaluar la app, cargando el puntaje del doctor y de la aplicación */
function useEvaluateCSATApp(uid: string){
	return useMutation(['Megalith-Query','useEvaluateApp', uid], async (data : IEvaluateDataCSAT) => {
		let token
		try {
			token = await getFirebaseIdToken()
		} catch (err) {
			throw new Error(`${err}`)
		}

		const headers = { 
			'Authorization': `Bearer ${token}`,
			'uid': uid, 
			'x-api-key': process.env.REACT_APP_UMA_BACKEND_APIKEY 
		}

		const response = axios.post(feedbackCSAT, data, { headers })

		return response
	})
}

export default useEvaluateCSATApp