import { ICoverageData } from './ICoverageData'
import useChangeCoverage from './useChangeCoverage'
import useDeleteCoverage from './useDeleteCoverage'
import useGetOSSList, {CuteCorporate, ICorporate} from './useGetOSSList'
import useRegisterCoverage, {IRegisterPatientCoverage} from './useRegisterCoverage'
import useSearchCorporate from './useSearchCorporate'
import useUpdateCoverage from './useUpdateCoverage'

export {
	useChangeCoverage,
	useDeleteCoverage,
	useGetOSSList,
	useRegisterCoverage,
	useSearchCorporate,
	useUpdateCoverage
}

export type {
	ICoverageData,
	CuteCorporate,
	ICorporate,
	IRegisterPatientCoverage
}