import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { refund_service } from '@/config/endpoints'
import { useMutation } from 'react-query'
import { errorHandler } from '@/config/ErrorBoundary'
interface IRefundSubscription {
	product: 'especialista' | 'onlinedoctor', // lo de onlinedoctor no esta chequeado
	specialty: string,
	patientUid: string
}

function useRefundSubscription( {product,specialty, patientUid} : IRefundSubscription){
	return useMutation(['UmaNodeServer-Query','refundSubscription', product,specialty, patientUid], async()=>{
	
		let firebaseToken : string

		try {
			firebaseToken = await getFirebaseIdToken()
		} catch(err) {
			if(errorHandler) errorHandler.report(err as Error)
			throw new Error (`${err}`)
		}

		const headers = {
			'Content-Type': 'Application/Json',
			Authorization: `Bearer ${firebaseToken}`,
		}

		return axios.post(
			refund_service,
			{
				product: product,
				specialty: specialty,
				uid: patientUid
			},
			{ headers }
		)

	},
	)
}

export default useRefundSubscription