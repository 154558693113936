import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { useMutation } from 'react-query'

/** Este es el endpoint viejo que sirve para cambiar el corporate_norm y corporate del usuario */
async function updateCoverage( uid: string, coverage: string, dependantUid?: string) {
	const firebaseToken = await getFirebaseIdToken()
	const path = dependantUid ? 
		`${UMA_BACKEND_URL}/coverages/dependant/primary` :
		`${UMA_BACKEND_URL}/coverages/primary`

	const headers = { 
		'Content-Type': 'Application/Json', 
		'Authorization': `Bearer ${firebaseToken}` ,
		'uid': uid, /** Este siempre es el del usuario principal */
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
	}

	await axios.post(path, {
		uid: dependantUid ?? uid,
		corporateId: coverage
	}, { headers, timeout: 10000})
}

/**
 * 
 * @param uid uid del paciente principal
 * @returns actualiza la coverage principal del paciente
 */
function useUpdateCorporate(uid: string, dependantUid?: string) {
	return useMutation(['Megalith-Query','updatePatientCorporate'], async(coverage : string) => {
		return updateCoverage(uid, coverage, dependantUid)
	})
}

export default useUpdateCorporate
