import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { closeChat } from '@/config/endpoints'
import { useMutation } from 'react-query'

interface ICloseChatData {
	uid : string, 
	providerUid: string, 
	type: 'online'
	assignation_id: string
}

function useCloseChat({ uid, providerUid, assignation_id, type} : ICloseChatData){
	return useMutation(['UmaNodeServer-Query', 'CloseChat'], async() => {
		const token = await getFirebaseIdToken()

		const headers = { 
			'Content-Type': 'Application/Json', 
			'Authorization': `Bearer ${token}`,
		}

		axios.post(
			closeChat, 
			{
				uid: uid,
				providerUid: providerUid,
				assignation_id: assignation_id,
				type: type
			},
			{
				headers
			}
		)
	})
}

export default useCloseChat