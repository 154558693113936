import { check_patient_field } from '@/config/endpoints'
import { CheckField, PatientField } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'

type Options = UseMutationOptions<CheckFieldResponse, AxiosError, CheckField>
type CheckFieldResponse = Partial<Record<PatientField, boolean>>
export default function useCheckPatientFields (uid: string, options?: Options) {
	return useMutation<CheckFieldResponse, AxiosError, CheckField>({
		mutationKey: ['MegalithQuery', 'CheckPatientField'],
		mutationFn: async (data: CheckField) => {
			const firebaseToken = await getFirebaseIdToken()

			const headers = {
				'Content-Type': 'Application/Json',
				'Authorization': `Bearer ${firebaseToken}`,
				uid,
			}

			const response = await axios.post<CheckFieldResponse>(`${check_patient_field}`, data, { headers })
			return response.data
		},
		...options,
	})
}