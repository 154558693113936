import { IPatient } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { Timestamp } from 'firebase/firestore'
import { UseQueryOptions, useQuery } from 'react-query'

type Queryoptions = Omit<UseQueryOptions<IPatient<Timestamp>[], unknown, IPatient<Timestamp>[], string[]>, 'queryKey' | 'queryFn'>

/** Trae los dependants validados de IOMA */
function useDependantsIOMA(uid: string, queryOptions : Queryoptions ) {
	return useQuery(
		['Megalith-Query', 'dependantsIOMA', uid],
		async () => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${token}`,
				uid: uid /** Este siempre es el del usuario principal */,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
			}

			const response = await axios.post<IPatient[]>(
				`${UMA_BACKEND_URL}/dependant/external/sync`,
				{
					uid: uid,
					coverageId: 'IOMA-APP',
				},
				{ headers }
			)

			return response.data
		},
		queryOptions
	)
}

export default useDependantsIOMA
