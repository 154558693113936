import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { patient_logs } from '@/config/endpoints'
import { errorHandler } from '@/config/ErrorBoundary'
import { useMutation } from 'react-query'

type EventTypes = 'sessionConnectionCreated' // Eventos de sesión
| 'sessionConnectionDestroyed'
| 'sessionConnected'
| 'sessionDisconnected'
| 'sessionReconnected'
| 'sessionReconnecting'
| 'sessionConecting'
| 'sessionCallStatus'
| 'sessionStreamCreated'
| 'sessionStreamDestroyed'
| 'sessionStreamPropertyChanged'
// Eventos de publisher
| 'publisherStreamDestroyed'
| 'publisherAccessAllowed'
| 'publisherAccessDenied'
| 'publisherAccessDialogClosed'
| 'publisherAccessDialogOpened'
| 'publisherAudioLevelUpdated'
| 'publisherDestroyed'
| 'publisherMediaStopped'
| 'publisherStreamCreated'
| 'publisherVideoDimensionsChanged'
| 'publisherVideoElementCreated'
// Eventos de suscriptor
| 'subscriberAudioBlocked'
| 'subscriberAudioLevelUpdated'
| 'subscriberAudioUnblocked'
| 'subscriberConnected'
| 'subscriberDestroyed'
| 'subscriberDisconnected'
| 'subscriberVideoDimensionsChanged'
| 'subscriberVideoDisabled'
| 'subscriberVideoDisableWarning'
| 'subscriberVideoDisableWarningLifted'
| 'subscriberVideoElementCreated'
| 'subscriberVideoEnabled'
| 'videoElementCreated'
| 'joinRoomFromToast'
| 'joinRoomFromQueue'
| 'joinFromLink'
| 'joinFromLinkWhatsapp'
| 'joinFromLinkEmail'
| 'joinFromLinkSms'
| 'joinFromLinkNotification'
| 'OT_AUTHENTICATION_ERROR'
| 'OT_INVALID_SESSION_ID'
| 'patientMuteCall'


export interface IPostLogs {
	events: EventTypes,
	value?: string
}

interface ILogRequest {
	uid: string;
	assignation_id: string;
	event: string;
	value?: string;
	uid_dependant: string|false;
}

/**
 * Escribe los logs del usuario en la DB
 *
 * @param patientUid uid del usuario
 * @param assignationId assignationId de la call que tuvo el problema
 * @param event Evento disparado que ocasionó la llamada
 * @param value Valor del evento
 */
async function postLogs( uid: string, assignationId: string, event : EventTypes, dependantUid?: string|false, value? : string) : Promise<void> {
	let userToken : string

	const log : ILogRequest = { 
		uid: uid, 
		assignation_id: assignationId, 
		event: event, 
		value: value, 
		uid_dependant: dependantUid || false
	}

	try {
		userToken = await getFirebaseIdToken()
	} catch(err){
		if(errorHandler) errorHandler.report(err as Error)
		throw new Error(`${err}`)
	}

	const headers = { 
		'Content-Type': 'Application/Json', 
		'Authorization': `Bearer ${userToken}`,
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
	}

	if (value){
		log.value = value
	}
	
	try {
		await axios.post(`${patient_logs}`, log,  { headers } )
	} catch(err) {
		if(errorHandler) errorHandler.report(err as Error)
		console.error('Can\'t update data', err)
	}
}



function usePostLogs(uid: string, assignationId: string, dependantUid: string|false = false){
	return useMutation(['UmaNodeServer-Query', 'PostLogs'],(
		async({events, value} : IPostLogs) => {
			postLogs(uid, assignationId, events, dependantUid, value)
		})
	)
}

export default usePostLogs