import axios from 'axios'
import store from '@/store/configStore'
import { cancel_guardia_appointment } from '@/config/endpoints'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { countries } from '@umahealth/entities'
import { useMutation, useQueryClient } from 'react-query'
import { ICancelData } from '@/services/interfaces/ICancelData'
import { errorHandler } from '@/config/ErrorBoundary'

export const cancelOptions : string[] = [
	'Me arrepentí',
	'Demasiada espera',
	'Otro'
]

/**
 *
 * Cancela un turno de Guardia o Especialista
 *
 * @param props props
 * @param props.complain Tipo de queja
 * @param props.whereIs De donde proviene la queja
 * @param props.complain_text Si el tipo es 'otro' acá esta la queja completa Si el tipo es 'otro' acá esta la queja completa
 * @param props.assignedAppointment Appointment activo que se quiere cancelar
 * @returns axios.post response of cancel
 */
function useCancelAppointment() {
	const queryClient = useQueryClient()

	const mutator = useMutation(['Megalith-Query','MakeComplaint'], async({
		uid,
		assignationId,
		origin,
		reason,
	} : Omit<ICancelData, 'country'>)=> {
		let token
		
		try {
			token = await getFirebaseIdToken()
		} catch (err) {
			if(errorHandler) errorHandler.report(err as Error)
			throw new Error(`${err}`)
		}


		const headers = { 
			'Authorization': `Bearer ${token}`,
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}

		const data : ICancelData = {
			assignationId: assignationId,
			origin: origin,
			country: process.env.NEXT_PUBLIC_COUNTRY as countries,
			uid: uid,
			reason,
		}

		return axios.post(cancel_guardia_appointment, data, { headers })
	},
	{
		onSuccess: () => {
			store.dispatch({ type: 'USER_ACTIVE_APPOINTMENT', payload: false })
			queryClient.invalidateQueries(['cancelAppointment'])
		}
	})
	
	return mutator
}

export default useCancelAppointment
