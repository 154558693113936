import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { useMutation } from 'react-query'
import { ICoverageData } from './ICoverageData'
import { UMA_BACKEND_URL } from '@/config/endpoints'

interface CoverageInfoObject {
	uid: string,
	dependantUid?: string,
	corporate: ICoverageData
}

/**
 * @param isDependant si el paciente es un dependant
 * 'Borra' un coverage, en realidad solo pone un flag en flase.
 */
async function changeCoverage(uid: string, dependantUid: string|undefined, coverageInfo: ICoverageData,  ) {
	const firebaseToken = await getFirebaseIdToken()

	const path = dependantUid ? `${UMA_BACKEND_URL}/coverages/dependant/change` : `${UMA_BACKEND_URL}/coverages/change`

	const headers = { 
		'Content-Type': 'Application/Json', 
		'Authorization': `Bearer ${firebaseToken}` ,
		'uid': uid, /** Este siempre es el del usuario principal */
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
	}
    
	const bodyRequest = {
		uid: dependantUid?? uid,
		corporate: {
			name: coverageInfo.name,
			affiliate_id: coverageInfo.affiliateId,
			plan: coverageInfo.plan
		}

	}

	await axios.post(path, bodyRequest, { headers })
}

/**
 * Cambia un coverage, incluyendo el nombre.
 * @param isDependant 
 * @returns mutation object
 */
function useChangeCoverage() {
	return useMutation(['Megalith-Query','deleteCoverage'], async( coverageData : CoverageInfoObject) => {
		return changeCoverage(coverageData.uid, coverageData.dependantUid, coverageData.corporate)
	})
}

export default useChangeCoverage    