import { resume_download } from '@/config/endpoints'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { errorHandler } from '@/config/ErrorBoundary'

interface IDownloadResult {
	created: boolean
	pdf?: {
		data: Uint8Array,
		type: 'Buffer'
	},
	url?: string
}

interface IDownloadReturn {
	created: boolean
	pdf?: Blob
	url?: string
}

interface IResumeProps {
	uid: string,
	medicalRecordId: string,
	rewrite?: boolean,
}

type Options = Omit<UseMutationOptions<IDownloadReturn, AxiosError>, 'mutationKey' | 'mutationFn'>

const downloadResume = async (uid: string, medicalRecordId: string, rewrite = true ): Promise<IDownloadReturn> => {
	let token: string
	let response: AxiosResponse<IDownloadResult>

	try {
		token = await getFirebaseIdToken()
	} catch (err) {
		if (errorHandler) errorHandler.report(err as Error)
		throw new Error(`${err}`)
	}

	const headers: AxiosRequestConfig = {
		headers: {
			'Content-Type': 'application/pdf',
			Authorization: `Bearer ${token}`,
			uid,
		}
	}
	const url = `${resume_download}/${uid}/${medicalRecordId}?rewrite=${rewrite}`

	try {
		response = await axios.get<IDownloadResult>(
			url,
			headers
		)
	} catch (err) {
		if (errorHandler) errorHandler.report(err as Error)
		throw new Error(`pdf resume creation error: ${err}`)
	}

	if (response.data) {
		const pdfBlob = new Blob([ new Uint8Array(response.data?.pdf?.data as Uint8Array) ], { type: 'application/pdf' })
		return {
			created: true,
			pdf: pdfBlob,
			url: response.data.url
		}
	} else {
		console.error('pdf resume creation goes wrong, response is:', response)
		return { created: false }
	}
}

export const useDowloadPdfResume = ({ uid, medicalRecordId, rewrite }: IResumeProps, options?: Options) => {
	return useMutation({
		mutationKey: ['MegalithQuery', 'DownloadResume'],
		mutationFn: async () => {
			return await downloadResume(uid, medicalRecordId, rewrite)
		}, 
		onError: (error) => {
			console.log(error)
			errorHandler?.report(`Resumen de atencion para uid: ${uid}, ${medicalRecordId} no se pudo generar. Error: ${error}`)
		},
		...options,
	})
}