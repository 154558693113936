import axios from 'axios'
import { search_corporate } from '@/config/endpoints'
import { useMutation, useQueryClient } from 'react-query'

/**
 * Devuelve las obras sociales mientras el usuario typea
 *
 * @returns mutator
 */
function useSearchCorporate () {
	const queryClient = useQueryClient()

	const mutator = useMutation(['Megalith-Query', 'searchCorporate'],async(text: string)=>{
    
		const headers = {
			'Content-Type': 'application/json;charset=UTF-8', 
		}
    
		return axios.post(
			search_corporate,
			{ text },
			{ 
				timeout: 2000, 
				headers
			}
		), {
			retry: 2,
			onSuccess: () => {
				queryClient.invalidateQueries(['corporate'])
			},
			onError: () => {
				console.error('can\'t find corporate')
			}
		}
	})

	return mutator
	

}

export default useSearchCorporate