import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { express_appointment } from '@/config/endpoints'
import { useMutation } from 'react-query'
import { errorHandler } from '@/config/ErrorBoundary'

export interface IExpressAppointment {
	patient: string;
	question: string;
	email: string;
}

async function postExpressAppointment( { patient, question, email } : IExpressAppointment) {
	try {
		const firebaseToken = await getFirebaseIdToken()
		const headers = { 
			'Content-Type': 'Application/Json', 
			'Authorization': `Bearer ${firebaseToken}` 
		}
		const expressAppointmentData = {
			email: email,
			question: question,
			patient: patient,
		}
		await axios.post(`${express_appointment}/`, expressAppointmentData, { headers })
	} catch(err){
		console.error(err)
		if(errorHandler) errorHandler.report(err as Error)
	}
}

function usePostExpressAppointment() {
	return useMutation(['UmaNodeServer-Query','postExpressAppointment'], async({ patient, question, email } : IExpressAppointment) => {
		return postExpressAppointment({
			email: email,
			question: question,
			patient: patient,
		})
	})
}

export default usePostExpressAppointment
