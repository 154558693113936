/**
 * 🚨 usar sólo en rutas privadas 🚨 ya que en caso contrario corremos el riesgo de que client sea null
 */

import { IClientState } from '@/OldRouter/Providers/ProviderClient'

/**
 * Checks if the given client is an IOMA client using the IOMA app.
 *
 * @param client - The client state object to evaluate.
 * @returns `true` if the client is an IOMA client using the IOMA app, otherwise `false`.
 */
export const isIomaAppClient = (client: IClientState | null): boolean => client?.client === 'IOMA'

/**
 * Checks if the given client is an IOMA client accessing through the UMA app.
 *
 * @param client - The client state object to evaluate.
 * @returns `true` if the client is an IOMA client accessing through the UMA app, otherwise `false`.
 */
export const isIomaFromUMAAppClient = (client: IClientState | null): boolean =>
	client?.client === 'IOMA-UMA'

/**
 * Checks if the given client is an IOMA client, regardless of whether they are using the IOMA app or the UMA app.
 *
 * @param client - The client state object to evaluate.
 * @returns `true` if the client is an IOMA client using either the IOMA app or the UMA app, otherwise `false`.
 */
export const isAnyIomaClient = (client: IClientState | null): boolean =>
	client?.client === 'IOMA' || client?.client === 'IOMA-UMA'

export const isOspreraClient = (client: IClientState | null): boolean =>
	client?.client === 'OSPRERA'
export const isUmaClient = (client: IClientState | null): boolean => client?.client === 'UMA'
export const isPFA = (client: IClientState | null): boolean => client?.client === 'POLICIA FEDERAL'
export const isFarmatodo = (client: IClientState | null): boolean => client?.client === 'FARMATODO'
