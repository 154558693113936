import { useMutation } from 'react-query'
import axios from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { serviceTypes } from '@umahealth/entities'
import { ICopayPrimaryResponse } from './useMegalithCopayPrimary'

interface ICopayCorporate {
	uid: string;
	dependantUid: string | false;
	service: serviceTypes;
	country: string;
	coverage: string
}

function useMegalithCopayCorporate(copay: ICopayCorporate) {
	return useMutation(
		['Megalith-Query','copayMegalith', copay.service, copay.country, copay.uid],
		async () => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const path = copay.dependantUid
				?    `${UMA_BACKEND_URL}/payment/${copay.service}/${copay.country}/copay/dependant/${copay.dependantUid}/coverage/${copay.coverage}` 
				:	 `${UMA_BACKEND_URL}/payment/${copay.service}/${copay.country}/copay/${copay.uid}/coverage/${copay.coverage}`

			const headers = {
				Authorization: `Bearer ${token}`,
				uid: copay.uid,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
			}

			const response = axios.get<ICopayPrimaryResponse>(path, { headers })

			return (await response).data
		}
	)
}

export default useMegalithCopayCorporate
