import { useMutation } from 'react-query'
import { patchCancelPatientSubscription } from '@/app/services/clientServices/patchCancelPatientSubscription'
interface ICancelSubscription {
	uid: string
	token: string | undefined
	formData: {
		preapprovalId: string | undefined
		documentId: string
		motive: string | null
	}
}

function useCancelSubscription() {
	return useMutation(
		['Megalith-Query', 'useCancelSubscription'],
		async (subscriptionData: ICancelSubscription) => {
			const response =
				await patchCancelPatientSubscription(subscriptionData)
			return response
		},
	)
}
export default useCancelSubscription
