import useDeletePill from './useDeletePill'
import useNewPill, {addPillboxMutationData} from './useNewPill'

export {
	useDeletePill,
	useNewPill
}

export type {
	addPillboxMutationData
}