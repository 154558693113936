import { countries } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { pillbox } from '@/config/endpoints'
import { queryClient } from '@/config/queryClient'
import { useMutation, UseMutationOptions } from 'react-query'

interface addPillboxData {
	country: countries;
	/** format: YYYY-MM-DD */
	end_date: string;
	/** format: YYYY-MM-DD */
	initial_date: string;
	medicine: string;
	/** format 24hs, example: 23:00, 11:00 */
	messages: {
		mon?: string[]; 
		tue?: string[];
		wed?: string[];	
		thu?: string[];	
		fri?: string[];	
		sat?: string[];	
		sun?: string[];	
	};
	personalized: boolean;
	uid: string;
	format: string;
	/** observaciones */
	obs: string;
	new: boolean;
	pillbox_id?: string;
}

export type addPillboxMutationData = Omit<addPillboxData, 'uid'|'country'>

type Options = Omit<UseMutationOptions<string | boolean, AxiosError, addPillboxMutationData>, 'mutationKey' | 'mutationFn'>

function useNewPill(uid: string, options?: Options) {
	return useMutation({
		mutationKey: ['Megalith-Query', 'pillbox'],
		mutationFn: async (addPillboxdata: addPillboxMutationData) => {
			const firebaseToken = await getFirebaseIdToken()

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${firebaseToken}`,
				uid,
			}

			const type = addPillboxdata.new ? 'post' : 'patch'
			const body = {
				initialDate: addPillboxdata.initial_date,
				endDate: addPillboxdata.end_date,
				medicine: addPillboxdata.medicine,
				messages: addPillboxdata.messages,
				personalized: addPillboxdata.personalized,
				format: addPillboxdata.format,
				obs: addPillboxdata.obs,
				uid: uid,
				country: process.env.NEXT_PUBLIC_COUNTRY,
				pillboxId: addPillboxdata.new ? undefined : addPillboxdata.pillbox_id
			}

			const response = await axios[type]<string | boolean>(
				`${pillbox}`,
				body,
				{
					headers,
				}
			)
			return response.data
		},
		onSuccess: () => queryClient.invalidateQueries('Pillbox'),
		...options,
	}
	)
}

export default useNewPill
