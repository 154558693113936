'use client'
import { UMA_BACKEND_URL } from '@/config/endpoints'

interface ICancelPatientSubscription {
	uid: string
	token: string | undefined
	formData: {
		preapprovalId: string | undefined
		documentId: string
		motive: string | null
	}	
}

export async function patchCancelPatientSubscription({
	uid,
	token,
	formData
}: ICancelPatientSubscription) {
	if (!token) {
		console.error('Se intentó cancelar la suscripción sin autorización')
		return null
	}

	if (!uid) {
		console.error('Se intentó cancelar la suscripción sin uid')
		return null
	}

	const result = await fetch(`${UMA_BACKEND_URL}/subscriptions/cancel`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			uid: uid,
		},
		cache: 'no-store',
		next: {
			tags: ['subscriptions'],
		},
		body: JSON.stringify(formData),
	})

	if (!result.ok) {
		console.error(
			`Error cancel subscription: ${result.status}, ${result.statusText}`,
		)
		throw new Error(
			`Error cancel subscription: ${result.status}, ${result.statusText}`,
		)
	}

	const data = await result.json()
	return data
}
