import axios, { AxiosError, AxiosResponse } from 'axios'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { confirm_guard_appointment } from '@/config/endpoints'
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query'
import { dependantUid } from '@umahealth/entities'

interface IConfirmPatient {
	assignationId: string,
	dependantUid: dependantUid,
	uid: string,
}
type Options = Omit<UseMutationOptions<AxiosResponse, AxiosError, IConfirmPatient>, 'mutationFn' | 'mutationKey'>

/**
 * Confirma que el paciente esta presente, dandole prioridad en la cola.
 *
 * @returns mutator
 */
function useConfirmpatient (options?: Options) {
	let firebaseToken : string
	const queryClient = useQueryClient()

	const mutator = useMutation({
		mutationKey: ['Megalith-Query', 'confirmAppointment'],
		mutationFn: async ({ assignationId, dependantUid, uid }: IConfirmPatient) => {
		
			firebaseToken = await getFirebaseIdToken()
			
			const headers = { 
				'Authorization': `Bearer ${firebaseToken}`,
				uid,
			}
			
			return axios.post(
				confirm_guard_appointment,
				{ 
					assignationId: assignationId,
					country: process.env.NEXT_PUBLIC_COUNTRY,
					dependantUid,
				},
				{ 
					headers,
				}
			)
		},
		onSuccess: () => {
			queryClient.clear()
		},
		onError: (err) => {
			console.error(`Can't confirm appointment - err: ${err}`)
		},
		...options,
	})

	return mutator
}

export default useConfirmpatient
