import { IBilling } from '@umahealth/entities'


interface IHaveToPay {
	subscription?: boolean,
	showPrice?: boolean, // Es si cubre la obra social true. Si es consultorio o no cubre la OSS es false.
	uma_creditos?: number,
	price?: IBilling | undefined,
	type?: 'online' | 'consultorio'

}

type specialistPaymentMethods = 'OSS' | 'Subscription' | 'UmaCreditos' | 'mercadopago' | 'consultorio'

/**
 * 
 * @param info objeto con la información para saber el método de pago
 * @param info.subscription si esta subscripto
 * @param info.showPrice Si cubre la OSS, si estas en consultorio da siempre false.
 * @param info.uma_creditos Cantidad de umacreditos
 * @param info.price Precio
 * @returns 
 */
function paymenthMethod({subscription, showPrice, uma_creditos, price, type} : IHaveToPay) : specialistPaymentMethods{

	if (type === 'consultorio'){
		return 'consultorio'
	}

	if (!showPrice && price?.price === 0){
		return 'OSS'
	}

	if (subscription){
		return 'Subscription'
	}

	if (price && uma_creditos && uma_creditos >= price?.price){
		return 'UmaCreditos'
	}

	return 'mercadopago'
}

export default paymenthMethod
