import axios, { AxiosError, AxiosResponse } from 'axios'
import { recovery_account } from '@/config/endpoints'
import { useMutation } from 'react-query'

interface IAccountRecovery {
	/** valor  */
	value: string,
	/** ws en MX dni en AR */
	field: 'ws'|'dni'
}

export interface RecoveryData {
	email: string,
	login: string[],
	sendEmailTo: string
}

function useAccountRecovery(){
	return useMutation<
	AxiosResponse<RecoveryData[]>,
	AxiosError,
	IAccountRecovery,
	string>
	(['useAccountRecovery'], async (data : IAccountRecovery) => {
    
		const headers = { 
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}

		const response = axios.post<RecoveryData[]>(recovery_account, data, { headers })

		return response

	})
}

export default useAccountRecovery