import { IPatient } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { register_patient } from '@/config/endpoints'
import { UseMutationOptions, useMutation } from 'react-query'

type Options = Omit<UseMutationOptions<Partial<IPatient>, AxiosError, Partial<IPatient>>, 'mutationFn' | 'mutationKey'>

/**
 * Completa la informacion del usuario proveniente del flujo de registro
 * @returns void
 */
function useRegisterPatient(options?: Options) {
	return useMutation({
		mutationKey: [ 'Megalith-Query', 'registerPatient' ],
		mutationFn: async (data: Partial<IPatient>) => {
			const firebaseToken = await getFirebaseIdToken()

			const headers = {
				'Content-Type': 'Application/Json',
				'Authorization': `Bearer ${firebaseToken}`,
				uid: data.id,
			}
			const response = await axios.post<Partial<IPatient>>(`${register_patient}`, data, { headers })
			return response?.data
		},
		...options,
	})
}

export default useRegisterPatient
