import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { countries } from '@umahealth/entities'
import { save_dni_images } from '@/config/endpoints'

export interface IAddIdentificationProps {
	frontImage: File,
	backImage: File,
}
interface IAddIdentificationPropsExtended extends IAddIdentificationProps {
	uid: string,
	country: countries,
}

type errorType = {
	exception: {
		response: {
			message: string,
			mimetypeDetected: string,
		},
		message: string,
		status: number,
		name: string,
	}
}

type Options = Omit<UseMutationOptions<void, AxiosError<errorType>, IAddIdentificationProps>, 'mutationFn' | 'mutationKey'>

/**
 * Añade las fotos del DNI o documento de identidad
 */
async function addIdentification({ uid, country, frontImage, backImage }: IAddIdentificationPropsExtended) {
	const firebaseToken = await getFirebaseIdToken()

	const headers = { 
		'Content-Type': 'Application/Json', 
		'Authorization': `Bearer ${firebaseToken}` ,
		'uid': uid, /** Este siempre es el del usuario principal */
	}

	/** Utilizamos formData porque en un body normal no podemos mandar el file */
	const saveAttachedData = new FormData()
	saveAttachedData.append('country', country)
	saveAttachedData.append('frontImage', frontImage)
	saveAttachedData.append('backImage', backImage)

	await axios.post(save_dni_images, saveAttachedData, { headers })
}

function useAddIdentification(uid: string, country: countries, options?: Options) {
	return useMutation<void, AxiosError<errorType>, IAddIdentificationProps>({
		mutationKey: ['Megalith-Query', 'addIdentification'],
		mutationFn: async({ frontImage, backImage }: IAddIdentificationProps) => {
			return addIdentification({ uid, country, frontImage, backImage })
		},
		...options,
	})
}

export default useAddIdentification    