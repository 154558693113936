import { countries, serviceTypes } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { useMutation } from 'react-query'

interface copayParams {
	uid: string;
	dependantUid?: string;
	service: serviceTypes;
	country: countries;
}

interface copayCoverage {
	coverage: string,
	copay: number,
	primary: boolean
}

export interface CopayResponse {
	coverages: copayCoverage[]
	service: serviceTypes
	umaCredits: number | false
	type: 'subscription' | 'corporate'
}

function useMegalithCopay(copay: copayParams) {
	return useMutation(
		['Megalith-Query','copayMegalith', copay.service, copay.country, copay.uid],
		async () => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const path = copay.dependantUid
				?    `${UMA_BACKEND_URL}/payment/${copay.service}/${copay.country}/copay/dependant/${copay.dependantUid}` 
				:	 `${UMA_BACKEND_URL}/payment/${copay.service}/${copay.country}/copay/${copay.uid}`

			const headers = {
				Authorization: `Bearer ${token}`,
				uid: copay.uid,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
			}

			const response = axios.get<CopayResponse>(path, { headers })

			return (await response).data
		}
	)
}

export default useMegalithCopay
