import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { useMutation, UseMutationOptions } from 'react-query'

/**
 * @param isDependant si el paciente es un dependant
 * 'Borra' un coverage, en realidad solo pone un flag en flase.
 */
async function deleteCoverage(uid: string, corporate: string, isDependant: boolean) {
	const firebaseToken = await getFirebaseIdToken()

	const path = isDependant ? `${UMA_BACKEND_URL}/coverages/dependant/remove` : `${UMA_BACKEND_URL}/coverages/remove`

	const headers = { 
		'Content-Type': 'Application/Json', 
		'Authorization': `Bearer ${firebaseToken}` ,
		'uid': uid, /** Este siempre es el del usuario principal */
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
	}
    
	const bodyRequest = {
		uid: uid,
		corporate: corporate
	}

	await axios.delete(path, {data:bodyRequest, headers })
}

interface IDeleteCoverage {
	uid: string,
	corporate: string
}

type Options = Omit<UseMutationOptions<void, AxiosError, IDeleteCoverage>, 'mutationKey' | 'mutationFn'>
/**
 * 'Borra' un coverage, en realidad solo pone un flag en false.
 * @param isDependant 
 * @returns mutation object
 */
function useDeleteCoverage(isDependant: boolean, options?: Options) {
	return useMutation({
		mutationKey: ['Megalith-Query','deleteCoverage'],
		mutationFn: async( coverageData : IDeleteCoverage) => {
			return deleteCoverage(coverageData.uid, coverageData.corporate, isDependant)
		},
		...options,
	})
}

export default useDeleteCoverage    