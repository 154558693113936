import useCancelSpecialistAppointment, { ICancelSpecialistAppointment } from './useCancelSpecialistAppointment'
import useMakeSpecialistAppointment, { PaymentData, IMakeSpecialistAppointment } from './useMakeSpecialistAppointment'
import usePaymentData, {IHaveToPay, IPaymentData, IPaymentData_consultorios} from './usePaymentData'
import usePractices, {IPracticesInfo} from './usePractices'

export {
	useCancelSpecialistAppointment,
	useMakeSpecialistAppointment,
	usePaymentData,
	usePractices
}

export type {
	ICancelSpecialistAppointment,
	PaymentData,
	IMakeSpecialistAppointment,
	IHaveToPay,
	IPaymentData,
	IPaymentData_consultorios,
	IPracticesInfo
}