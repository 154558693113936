import { medical_qa } from '@/config/endpoints'
import axios from 'axios'
import { useMutation } from 'react-query'

export interface IAiMedicalQaResponse {
	input: string
	output: string
}

async function sendMedicalQa(reasons: string): Promise<IAiMedicalQaResponse> {
	let attMotive = reasons
	if (reasons.includes('receta')) attMotive += '. Necesito renovar una receta.'
	const data = {
		text: attMotive,
		step: 'first'
	}
	try {
		const aiQaOutput = await axios.post(medical_qa, data)
		return aiQaOutput.data
	} catch (err) {
		throw new Error('An error occurred saving medical_qa')
	}
}

/**
 * 
 * @param reason motivo de consulta
 * @returns {IAiMedicalQaResponse} respuesta del modelo de medical_qa
 */

function useSendMedicalQa() {
	return useMutation(['AI-Query', 'useSendMedicalQa'], async (reason: string) => {
		return sendMedicalQa(reason)
	})
}

export { useSendMedicalQa } 