import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { delete_pillbox } from '@/config/endpoints'
import { queryClient } from '@/config/queryClient'
import { useMutation } from 'react-query'

interface DeletePillData {
	uid: string, 
	pillbox_id: string, 
}

function useDeletePill(){
	let firebaseToken : string

	return useMutation(['Megalith-Query', 'deletePill'],async( deletePillData : DeletePillData)=>{
		
		firebaseToken = await getFirebaseIdToken()
    
		const headers = {
			'Content-Type': 'Application/Json',
			Authorization: `Bearer ${firebaseToken}`,
			uid: deletePillData.uid
		}
    
		return axios.post(
			`${delete_pillbox}`,
			{ 
				uid: deletePillData.uid,
				pillboxId: deletePillData.pillbox_id,
			},
			{ 
				headers
			}
		)
	}, {
		onSuccess: () => queryClient.invalidateQueries('Pillbox')
	})
}

export default useDeletePill