import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { IFormDependant } from '@/components/User/AddDependant/AddDependant'
import { create_dependant } from '@/config/endpoints'
import { UseMutationOptions, useMutation } from 'react-query'
import { gender, pronouns, relationship } from '@umahealth/entities'

export interface IDependantData extends Omit<IFormDependant, 'no_ws'> {
	core_id: string,
	country: string,
	dni: string,
	dob: string,
	email: string,
	firstname: string,
	fullname: string,
	group: string,
	lastname: string,
	nationality: string,
	relationship: relationship
	sex: gender,
	ws: string,
	chosenName?: string,
	pronouns?: pronouns,
	relationDescription?: string
}

interface IResponse {
	created: boolean,
	uploadedDependantId: string
}
type Options = Omit<UseMutationOptions<IResponse, AxiosError<ICustomError>, IDependantData>, 'mutationKey' | 'mutationFn'>
interface ICustomError {
	exception: {
		response: {
			msg: string,
			type: string,
		}
	}
}

/** Permite añadir un dependant */
function useAddDependant(options?: Options){
	return useMutation<IResponse, AxiosError<ICustomError>, IDependantData>(['Adddependant'], async (data : IDependantData) => {
		let token
		
		try {
			token = await getFirebaseIdToken()
		} catch (err) {
			throw new Error(`${err}`)
		}
    
		const headers = { 'Content-Type': 'Application/Json', Authorization: `Bearer ${token}` }

		const response = await axios.post<IResponse>(`${create_dependant}`, data, { headers })

		return response.data

	}, {
		onError: options?.onError,
		onSuccess: options?.onSuccess,
	})
}

export default useAddDependant