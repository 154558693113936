import { requestTypes } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { feedback } from '@/config/endpoints'
import { useMutation } from 'react-query'

export interface IEvaluateData {
	assignationId: string,
	umaEval: number,
	docEval: number,
	reasons: string[],
	comments?: string,
	encounter: boolean | null,
	uid: string,
	type?: requestTypes
}

/** Permite evaluar la app, cargando el puntaje del doctor y de la aplicación */
function useEvaluateApp(uid: string){
	return useMutation(['Megalith-Query','useEvaluateApp', uid], async (data : IEvaluateData) => {
		let token
		try {
			token = await getFirebaseIdToken()
		} catch (err) {
			throw new Error(`${err}`)
		}
    
		const headers = { 
			'Authorization': `Bearer ${token}`,
			'uid': uid, 
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}

		const response = await axios.post(feedback, data, { headers })

		return response
	})
}

export default useEvaluateApp