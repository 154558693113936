import { countries, dependantUid, serviceTypes } from '@umahealth/entities'
import axios, { AxiosError } from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { UseQueryOptions, useMutation, useQuery } from 'react-query'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'

export interface ICopayParams {
	uid: string;
	country: countries;
	service: serviceTypes;
	dependantUid?: dependantUid;
}

export interface ICopayPrimaryResponse {
	copay: number
	service: serviceTypes
	type: 'subscription' | 'corporate'
	umaCredits: number | false
	approvalId?: string
	coverage?: string
}

type QueryOptions = Omit<UseQueryOptions<ICopayPrimaryResponse, AxiosError, ICopayPrimaryResponse>, 'queryKey' | 'queryFn'>

async function getMegalithCopayPrimary(copay: ICopayParams){
	let token

	try {
		token = await getFirebaseIdToken()
	} catch (err) {
		throw new Error(` Error get firebase id token ${err}`)
	}

	const path = copay.dependantUid
		?    `${UMA_BACKEND_URL}/payment/${copay.service}/${copay.country}/copay/dependant/${copay.dependantUid}/primary` 
		:	 `${UMA_BACKEND_URL}/payment/${copay.service}/${copay.country}/copay/${copay.uid}/primary`

	const headers = {
		Authorization: `Bearer ${token}`,
		uid: copay.uid,
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
	}

	const response = await axios.get<ICopayPrimaryResponse>(path, { headers })

	return response.data
}

function useMegalithCopayPrimaryMutation(copay: ICopayParams) {
	return useMutation(
		['Megalith-Query','PrimaryCopay', copay.service, copay.country, copay.uid],
		() => getMegalithCopayPrimary(copay)
	)
}
function useMegalithCopayPrimaryQuery(copay: ICopayParams, options?: QueryOptions) {
	return useQuery<ICopayPrimaryResponse, AxiosError, ICopayPrimaryResponse>({
		queryKey: ['Megalith-Query','PrimaryCopay', copay.service, copay.country, copay.uid],
		queryFn: () => getMegalithCopayPrimary(copay),
		...options,
	})
}


export { getMegalithCopayPrimary, useMegalithCopayPrimaryQuery }
export default useMegalithCopayPrimaryMutation
