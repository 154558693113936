import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { useMutation } from 'react-query'
import { currencies } from '@/components/Payment/currencies'
import { chatAtt_make_appointment, chatAtt_make_appointment_dependant } from '@/config/endpoints'
import { ICopayPrimaryResponse } from '../Payments/useMegalithCopayPrimary'


/*
* payment_data: method: 'UmaCreditos', id: 'UmaCreditos' price: copayPrice.
* payment_data: method: 'Corporate',  id: '$CORPORATE_NAME', price: 0 (o un copago menor en algunos casos)
* payment_data: method: 'Subscription', id: '$SUSCRIPTION_ID', price: 0 
* payment_data: method: 'MercadoPago', id: '$MERCADOPAGO_ID': price: copayPrice
*/
export interface IPaymentDataBackend {
	method: string,
	id: string,
	price: number
}

interface IChatAttPaymentData extends ICopayPrimaryResponse {
	/** Id que devuelve MP luego de pagar una consulta */
	mercadopagoId : string|null
	uid: string,
	patientUid: string,
	corporate_norm: string,
	attReasons: string
}

function useMakeChatAttAppointment() {


	return useMutation(
		['Firestore-Query', process.env.NEXT_PUBLIC_COUNTRY],
		async (chatAttData: IChatAttPaymentData) => {

			const isDependant = chatAttData.patientUid != chatAttData.uid

			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const path = (isDependant) ? `${chatAtt_make_appointment_dependant}/${chatAttData.patientUid}` : chatAtt_make_appointment

			const headers = {
				'Authorization': `Bearer ${token}`,
				'uid': chatAttData.uid,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
			}

			let paymentDataBackend: IPaymentDataBackend

			if (chatAttData.mercadopagoId) {
				paymentDataBackend = {
					method: 'MercadoPago',
					id: chatAttData.mercadopagoId ?? 'mercadopagoId not found',
					price: chatAttData.copay,
				}
			}
			else if (chatAttData.umaCredits && chatAttData.umaCredits >= chatAttData.copay) {
				paymentDataBackend = {
					method: 'UmaCreditos',
					id: 'UmaCreditos',
					price: chatAttData.copay
				}
			}
			else if (chatAttData.copay === 0) {
				paymentDataBackend = {
					method: 'Corporate',
					id: chatAttData.corporate_norm,
					price: chatAttData.copay
				}
			}
			else {
				paymentDataBackend = {
					method: 'MercadoPago',
					id: chatAttData.mercadopagoId ?? 'mercadopagoId not found',
					price: chatAttData.copay,
				}
			}

			const makeAppointmentRequest = {
				country: process.env.NEXT_PUBLIC_COUNTRY,
				motivosDeConsulta: chatAttData.attReasons,
				uid: chatAttData.uid,
				dependantUid: chatAttData.patientUid!==chatAttData.uid?chatAttData.patientUid : false,
				...(chatAttData.patientUid && {pediatric: false}),
				paymentData: {
					...paymentDataBackend,
					trueMethod: paymentDataBackend.method,
					full_price: paymentDataBackend.price,
					currency: currencies[process.env.NEXT_PUBLIC_COUNTRY],
				}
			}

			const response = axios.post(path, makeAppointmentRequest, {
				headers,
			})

			return response
		},
		{
			retry: 0
		}
	)
}

export default useMakeChatAttAppointment