import { dependantUid, IPatient } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { update_dependant, update_patient } from '@/config/endpoints'
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query'

export interface ICorporateInfo {
	corporate_norm: string;
}

type Options = Omit<UseMutationOptions<void, AxiosError, Partial<IPatient>>, 'mutationFn' | 'mutationKey'>
interface IUids {
	user: string,
	dependant?: dependantUid,
}

export async function updatePatient(uids: IUids, profileData: Partial<IPatient>) {
	const firebaseToken = await getFirebaseIdToken()
	const path = uids.dependant ? update_dependant : update_patient

	const headers = {
		'Content-Type': 'Application/Json',
		'Authorization': `Bearer ${firebaseToken}`,
		'uid': uids.user, /** Este siempre es el del usuario principal */
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
	}

	const fullname = profileData?.firstname ? `${profileData?.firstname} ${profileData?.lastname}` : null

	const userInfo = {
		...profileData
	}

	if (fullname) {
		userInfo.fullname = fullname
	}

	await axios.post(path, {
		uid: uids.dependant || uids.user,
		data: userInfo,
	}, { headers })
}

/**
 * Actualiza los datos del paciente o dependant
 * @param uids uids del usuario principal y del dependant si corresponde
 * @returns actualiza la coverage principal del paciente
 */
function useUpdatePatient(uids: IUids, options?: Options) {
	const queryClient = useQueryClient()
	return useMutation<void, AxiosError, Partial<IPatient>>({
		mutationKey: [ 'Megalith-Query', 'updatePatient' ],
		mutationFn: async (profileData: Partial<IPatient>) => {
			return updatePatient(uids, profileData)
		},
		onSuccess: () => queryClient.clear(),
		...options,
	})
}

export default useUpdatePatient
