import useCancelAppointment, {cancelOptions} from './useCancelAppointment'
import useMakeAppointment from './useMakeAppointment'
import usePostExpressAppointment, {IExpressAppointment} from './usePostExpressAppointment'
import useMakeComplaint from '@/services/hooks/requests/Appointments/useMakeComplaint'

export {
	useCancelAppointment,
	useMakeAppointment,
	usePostExpressAppointment,
	useMakeComplaint
}

export type {
	cancelOptions,
	IExpressAppointment
}

