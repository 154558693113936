import axios, { AxiosError, AxiosResponse } from 'axios'
import { recovery_final_step } from '@/config/endpoints'
import { useMutation } from 'react-query'
import { RecoveryData } from './useAccountsRecovery'

interface RecoveryFinalStepResponse {
	email: string
	login: string[]
}

function useRecoveryFinalStep(){
	return useMutation<
	AxiosResponse<RecoveryFinalStepResponse>,
	AxiosError,
	RecoveryData,
	string>
	(['useAccountRecoveryFinalStep'], async (data : RecoveryData ) => {
    
		const headers = { 
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}

		const response = axios.post<RecoveryFinalStepResponse>(recovery_final_step, {
			email: data.sendEmailTo,
			login: data.login
		}, { headers })

		return response

	})
}

export default useRecoveryFinalStep