import useEvaluateApp, {IEvaluateData} from './useEvaluateApp'
import useEvaluateCSATApp, {IEvaluateDataCSAT} from './useEvaluateCSATApp'

export {
	useEvaluateApp,
	useEvaluateCSATApp
}

export type {
	IEvaluateData,
	IEvaluateDataCSAT
}