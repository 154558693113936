import axios from 'axios'
import { postRequiredPasswordChange, UMA_BACKEND_APIKEY } from '@/config/endpoints'
import { useMutation } from 'react-query'
import { auth } from '@/config/DBConnection'
import { getIdToken } from 'firebase/auth'

export const usePostRequiredPasswordChange = () => {
	return useMutation(async () => {
		try {
			if (auth?.currentUser) {
				const token = await getIdToken(auth?.currentUser)
				const uid = auth?.currentUser?.uid
				const headers = {
					'Content-Type': 'application/json',
					'x-api-key': UMA_BACKEND_APIKEY,
					Authorization: `Bearer ${token}`,
				}
				const { data } = await axios.post(postRequiredPasswordChange(uid), {}, { headers })
				return data
			}
		} catch (error) {
			console.error(error)
		}
	})
}
