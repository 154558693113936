import axios from 'axios'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { queue_unsuspend } from '@/config/endpoints'
import { useMutation, useQueryClient } from 'react-query'

/**
 * Desuspende un paciente, usa ReactQuery
 *
 * @returns mutator
 */
function useResumePatient () {
	const queryClient = useQueryClient()

	const mutator = useMutation(['Megalith-Query', 'ResumePatient'],async(assignation_id : string)=>{
		const firebaseToken = await getFirebaseIdToken()
	
		const headers = { 
			'Authorization': `Bearer ${firebaseToken}`,
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}
    
		return axios.post(
			queue_unsuspend,
			{ assignationId: assignation_id, country: process.env.NEXT_PUBLIC_COUNTRY },
			{ 
				headers
			}
		)
	}, {
		retry: 2,
		onSuccess: () => {
			queryClient.clear()
		},
		onError: () => {
			console.error('can\'t unsuspend appointment')
		}
	})

	return mutator
	

}

export default useResumePatient
