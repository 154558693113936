import { IPatient } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { autonomous_next } from '@/config/endpoints'
import { useMutation } from 'react-query'

interface IAutonomousNextData {
	qa_next?: {
		pregunta: []
		respuesta: []
		key: ''
	}
	qa_acumulado: {
		last: []
	}
	answers: {
		lat: ''
		lon: ''
	}
	resx: string
	biomarkers: string
}

function useGetAutonomousNext(
	patient: IPatient,
	assignation: string,
) {
	return useMutation(
		['UmaNodeServer-Query', 'useGetAutonomousNext'],
		async (autonomousNextData: IAutonomousNextData) => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const headers = {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}

			const answers = autonomousNextData.answers
			const biomarkers = autonomousNextData.biomarkers
			const qa_acumulado = autonomousNextData.qa_acumulado
			const qa_next = autonomousNextData.qa_next
			const resx = autonomousNextData.resx

			const autonomousNextRequest = {
				biomarker: biomarkers??'',
				lab_id: assignation??'',
				lat: answers.lon??'',
				lon: answers.lat??'',
				qa1: answers??'',
				qa_next: [`${qa_next?.pregunta} .${resx}`],
				qa_acumulado: [...qa_acumulado.last, `${qa_next?.pregunta} .${resx}`],
				key: qa_next?.key ? qa_next?.key : '0',
				uid: patient?.uid,
				uid_dependant: false,
				sex: patient?.sex??'',
			}

			const response = axios.post(autonomous_next, autonomousNextRequest, {
				headers,
			})

			return response
		},
		{
			retry: 0
		}
	)
}

export default useGetAutonomousNext