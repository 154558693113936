import axios from 'axios'
import { useMutation } from 'react-query'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { cancel_specialist_appointment_online, cancel_specialist_appointment_onsite } from '@/config/endpoints'
import { queryClient } from '@/config/queryClient'

export interface ICancelSpecialistAppointment {
	patientUid: string,
	assignation_id: string,
	type: 'online' | 'consultorio',
	action: {
		action: string,
		by: string,
		date: string
	},
	cancelOrigin: 'USER' | 'DELAY'
}

/**
 *
 * Cancela un turno de especialista
 */
function useCancelSpecialistAppointment() {

	const mutator = useMutation(['Megalith-Query','cancelSpecialistAppointment'], async({
		patientUid,
		assignation_id,
		type,
		action,
		cancelOrigin
	} : ICancelSpecialistAppointment)=> {
		const endpoint = type === 'online' ? cancel_specialist_appointment_online : cancel_specialist_appointment_onsite
		
		const data = {
			assignation_id: assignation_id,
			country: process.env.NEXT_PUBLIC_COUNTRY,
			uid: patientUid,
			action: action,
			cancelOrigin: cancelOrigin
		}
		const token = await getFirebaseIdToken()
		const headers = { 
			'Authorization': `Bearer ${token}`,
			'uid': patientUid, 
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}
		return axios.post(endpoint, data, { headers })
	},
	{
		onSuccess : () =>{
			queryClient.clear()
		}
	})
	
	return mutator
}

export default useCancelSpecialistAppointment
