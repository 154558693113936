import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { prescription_pdf } from '@/config/endpoints'
import { useMutation, UseMutationOptions } from 'react-query'
import { errorHandler } from '@/config/ErrorBoundary'

interface IDownloadResponse {
	created: boolean,
	url: string,
	pdf?: {
		data: Uint8Array,
		type: 'Buffer'
	},
}

export interface IDownloadReturn {
	created: boolean,
	url?: string,
	pdf?: Blob,
}

interface IDownloadParams {
	prescriptionId: string,
	rewrite: boolean,
}

type Options = Omit<UseMutationOptions<IDownloadReturn, AxiosError, IDownloadParams>, 'mutationKey' | 'mutationFn'>

function usePrescriptionPDF(uid: string, options?: Options) {
	return useMutation({
		mutationKey: ['Megalith-Query', 'prescriptionPDF'],
		mutationFn: async ({prescriptionId, rewrite}: IDownloadParams) => {
			let response: AxiosResponse<IDownloadResponse>
			const firebaseToken = await getFirebaseIdToken()
			
			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${firebaseToken}`,
				uid,
			}
			
			try {
				response = await axios.get<IDownloadResponse>(
					`${prescription_pdf}/${process.env.NEXT_PUBLIC_COUNTRY}/${prescriptionId}?rewrite=${rewrite}`,
					{ headers }
				)
			} catch (err) {
				if (errorHandler) errorHandler.report(err as Error)
				throw new Error(`[ usePrescriptionPdf ] => Error downloading prescriptionPdf id: ${prescriptionId} - error: ${err}`)
			}

			if (response.data) {
				const pdfBlob = new Blob([ new Uint8Array(response.data?.pdf?.data as Uint8Array) ], { type: 'application/pdf' })
				return {
					created: true,
					pdf: pdfBlob,
					url: response.data.url
				}
			} else {
				console.error('[ usePrescriptionPdf ] => PdfPrescription creation goes wrong, response is:', JSON.stringify(response))
				return { created: false }
			}
		},
		...options,
	}
	)}

export default usePrescriptionPDF