import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { chatAtt_checkout } from '@/config/endpoints'
import { useMutation, UseMutationOptions } from 'react-query'
import { errorHandler } from '@/config/ErrorBoundary'

export interface IMercadopagoId {
	corporate: string,
	deferred: string,
	dependant: boolean,
	dependantUid: string | false,
	docId: string,
	providerUid?: string,
	specialty: string,
	uid: string,
}

interface IChatAttData {
	deferred: string
	uid: string
}

export interface IChatAttCheckoutResponse {
	id: string,
	/**
	 * @deprecated era para la app nativa
	 */
	link: string,
	price: number,
	showFakePrice: boolean,
	initialPrice?: number,
}

type QueryOptions = | Omit<UseMutationOptions<IChatAttCheckoutResponse, AxiosError<unknown>, string, unknown>, 'mutationFn' | 'mutationKey'> | undefined;

function useChatAttCheckout(
	data: IChatAttData,
	options?: QueryOptions
) {
	return useMutation<IChatAttCheckoutResponse, AxiosError, string>(
		['Megalith-Query', 'chatAttCheckout', data],
		async () => {
			let firebaseToken: string
			let transactionInfo: AxiosResponse<IChatAttCheckoutResponse>

			try {
				firebaseToken = await getFirebaseIdToken()
			} catch (err) {
				if (errorHandler) errorHandler.report(err as Error)
				throw new Error(`${err}`)
			}

			const headers = {
				'Authorization': `Bearer ${firebaseToken}`,
				'uid': data.uid,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
			}

			try {
				transactionInfo = await axios.post<IChatAttCheckoutResponse>(
					chatAtt_checkout,
					{
						...data,
						country: process.env.NEXT_PUBLIC_COUNTRY,
					},
					{ headers }
				)
			} catch (err) {
				if (errorHandler) errorHandler.report(err as Error)
				throw new Error(`${err}`)
			}

			return transactionInfo.data
		},
		options
	)
}

export default useChatAttCheckout
