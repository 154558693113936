import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { constancy_download } from '@/config/endpoints'
import { errorHandler } from '@/config/ErrorBoundary'
import { UseMutationOptions, useMutation } from 'react-query'

interface IDownloadResult {
	created: boolean
	pdf?: {
		data: Uint8Array,
		type: 'Buffer'
	},
	url?: string
}
interface IUseDownloadConstancyProps {
	uid: string,
	medicalRecordId: string,
	rewrite?: boolean
}

export interface IDownloadReturn {
	created: boolean,
	pdf?: Blob,
	url?: string
}

type Options = Omit<UseMutationOptions<IDownloadReturn, AxiosError>, 'mutationKey' | 'mutationFn'>

/**
 * Descarga una constancia de atención
 *
 * @returns AxiosResponse si el backend contesta y sino null
 */
async function downloadConstancy({ uid, medicalRecordId, rewrite = true }: IUseDownloadConstancyProps): Promise<IDownloadReturn> {
	let token: string
	let response: AxiosResponse<IDownloadResult>

	try {
		token = await getFirebaseIdToken()
	} catch (err) {
		if (errorHandler) errorHandler.report(err as Error)
		throw new Error(`${err}`)
	}

	const headers: AxiosRequestConfig = {
		headers: {
			'Content-Type': 'application/pdf',
			Authorization: `Bearer ${token}`,
			uid,
		}
	}
	const url = `${constancy_download}/${uid}/${medicalRecordId}?rewrite=${rewrite}`
	try {
		response = await axios.get<IDownloadResult>(
			url,
			headers
		)
	} catch (err) {
		if (errorHandler) errorHandler.report(err as Error)
		throw new Error(`pdf creation error: ${err}`)
	}

	if (response.data) {
		const pdfBlob = new Blob([ new Uint8Array(response.data?.pdf?.data as Uint8Array) ], { type: 'application/pdf' })
		return {
			created: true,
			pdf: pdfBlob,
			url: response.data.url
		}
	} else {
		console.error('pdfConstancy creation goes wrong, response is:', response)
		return { created: false }
	}
}
function useDownloadConstancy({ uid, medicalRecordId, rewrite }: IUseDownloadConstancyProps, options?: Options) {
	return useMutation({
		mutationKey: ['DownloadConstancy'],
		mutationFn: async () => {
			return await downloadConstancy({ uid, medicalRecordId, rewrite })
		},
		retry: false,
		onError: () => {
			errorHandler?.report(`Constancia para uid: ${uid}, ${medicalRecordId} no se pudo generar`)
		},
		...options,
	})
}

export default useDownloadConstancy
