import { pending_subscription } from '@/config/endpoints'
import { useMutation } from 'react-query'
import { countries } from '@umahealth/entities'

interface IPendingSubscriptionProps {
	uid: string
	token: string | undefined
}

interface IPendingSubscriptionMutation {
	mpPlanId: string
	country: countries
}

function useCreateSubscription({ uid, token }: IPendingSubscriptionProps) {
	return useMutation(
		['Megalith-Query', 'useCreatePendingSubscription'],
		async (pendingSubscriptionMutation: IPendingSubscriptionMutation) => {
			if (!token) {
				throw new Error('Not a valid token')
			}

			if (!uid) {
				throw new Error('Not a valid uid')
			}

			const response = await fetch(pending_subscription, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					uid: uid,
				},
				body: JSON.stringify(pendingSubscriptionMutation),
			})

			if (!response.ok) {
				throw new Error('Error creating pending subscription')
			}

			const responseText = await response.text()

			return responseText
		},
	)
}

export default useCreateSubscription
