import axios, { AxiosError } from 'axios'
import { search_corporate } from '@/config/endpoints'
import { useMutation } from 'react-query'

export interface ICorporate {
	input: string; 
	output: Array<{
		score: number;
		canon: string;
		in_db: string;
	}>
}

export interface CuteCorporate {
	label: string;
	value: string;
}

// pasar a react-query
async function getOSSList(search: string) : Promise<CuteCorporate[]|undefined>{
	const headers = {
		'Content-Type': 'application/json;charset=UTF-8',
	}

	const response = await axios.post<ICorporate>(
		search_corporate,
		{
			text: search,
			country: process.env.NEXT_PUBLIC_COUNTRY
		},
		{ headers }
	)

	if (response) {
		if (response.data.output.length === 0){
			return undefined
		}
		const formatData = response.data.output.map((item) => {
			return {
				label: item.canon,
				value: item.in_db,
			}
		})
		return formatData
	}
}

/**
 * Retorna la lista de obras sociales posibles de acuerdo a un input
 * @returns Array con index, lavel y value para las distintas obras sociales
 */
function useGetOSSList () {
	return useMutation<CuteCorporate[] | undefined, AxiosError, string>({
		mutationKey: ['Megalith-Query', 'getOSSList'],
		mutationFn: async (search : string) => {
			return getOSSList(search)
		}
	})
}

export default useGetOSSList