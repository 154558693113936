import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { useMutation } from 'react-query'
import { ICoverageData } from './ICoverageData'
import { register_coverage, register_coverage_dependant } from '@/config/endpoints'

export interface IRegisterPatientCoverage {
	coverage: ICoverageData
	uid: string
	dependantUid: string | undefined
}

/**
 * RegisterPatientCorporate registra un nuevo paciente.
 * @param uid uid del usuario principal para hacer la llamada al back
 * @param corporateInfo Datos necesarios para registrar la nueva obra social
 * Si la obra social ya existe dara error
 */
export async function registerCoverage(
	uid: string,
	dependantUid: string | undefined,
	coverageInfo: ICoverageData,
) {
	const firebaseToken = await getFirebaseIdToken()

	const path = dependantUid
		? register_coverage_dependant
		: register_coverage

	const headers = {
		'Content-Type': 'Application/Json',
		Authorization: `Bearer ${firebaseToken}`,
		uid: uid /** Este siempre es el del usuario principal */,
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
	}
	const bodyRequest = {
		uid: dependantUid ?? uid,
		corporates: [
			{
				name: coverageInfo.name,
				affiliate_id: coverageInfo.affiliateId,
				plan: coverageInfo.plan,
				userInput: coverageInfo.userInput,
				credentialVersion: coverageInfo.credentialVersion || null,
			},
		],
	}

	await axios.post(path, bodyRequest, { headers })
}

/**
 * useUpdateCoverage registra un nueva coverage
 */
function useRegisterCoverage() {
	return useMutation(
		['Megalith-Query', 'registerPatientCoverage'],
		async (corporateInfo: IRegisterPatientCoverage) => {
			return registerCoverage(
				corporateInfo.uid,
				corporateInfo.dependantUid,
				corporateInfo.coverage,
			)
		},
	)
}

export default useRegisterCoverage

