import { validate_dni } from '@/config/endpoints'
import { documentType, gender } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'

interface IValidateDniData {
	dni: string,
	documentType: documentType,
	fullname: string,
	sex: gender,
}

interface IValidateDniResponse {
	isValid: boolean,
	vertexResponse: {
		dni: string,
		nombre: string,
		sexo: gender,
	},
}

type errorType = {
	exception: {
		response: string,
		message: string,
		status: number,
		name: string,
	}
}

type Options = Omit<UseMutationOptions<IValidateDniResponse, AxiosError<errorType>, IValidateDniData>, 'mutationKey' | 'mutationFn'>

export const useValidateDni = (uid: string, options?: Options) => {
	return useMutation({
		mutationKey: ['MegalithQuery', 'validateDni'],
		mutationFn: async (data: IValidateDniData) => {
			const firebaseToken = await getFirebaseIdToken()

			const headers = {
				'Content-Type': 'Application/Json',
				'Authorization': `Bearer ${firebaseToken}`,
				uid,
			}

			const response = await axios.post<IValidateDniResponse>(validate_dni, data, {headers})
			return response?.data
		},
		...options,
	})
}