import axios from 'axios'
import store from '@/store/configStore'
import { save_complaint } from '@/config/endpoints'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from '@/config/ErrorBoundary'
import { IMakeComplaintData } from '@/services/interfaces/IMakeComplaint'

export const cancelOptions : string[] = [
	'Me arrepentí',
	'Demasiada espera',
	'Otro'
]

/**
 *
 * Cancela un turno de Guardia o Especialista
 *
 * @param props props
 * @param props.complain Tipo de queja
 * @param props.whereIs De donde proviene la queja
 * @param props.complain_text Si el tipo es 'otro' acá esta la queja completa Si el tipo es 'otro' acá esta la queja completa
 * @param props.assignedAppointment Appointment activo que se quiere cancelar
 * @returns axios.post response of cancel
 */
function useMakeComplaint() {
	const queryClient = useQueryClient()

	const mutator = useMutation(['Megalith-Query','CancelAppointment'], async({
		assignationId,
		complainText
	} : Omit<IMakeComplaintData, 'country'>)=> {
		let token
		
		try {
			token = await getFirebaseIdToken()
		} catch (err) {
			if(errorHandler) errorHandler.report(err as Error)
			throw new Error(`${err}`)
		}


		const headers = { 
			'Authorization': `Bearer ${token}`,
			'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY 
		}

		const data : IMakeComplaintData = {
			assignationId,
			complainText
		}

		return axios.post(save_complaint, data, { headers })
	},
	{
		onSuccess: () => {
			store.dispatch({ type: 'USER_ACTIVE_APPOINTMENT', payload: false })
			queryClient.invalidateQueries(['saveComplaint'])
		}
	})
	
	return mutator
}

export default useMakeComplaint
