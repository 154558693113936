import { subscription_discount } from '@/config/endpoints'
import { countries } from '@umahealth/entities'
import { useMutation } from 'react-query'
import axios from 'axios'

interface IApplyDiscountData {
	country: countries
	preapprovalPlan: string
	preapprovalPlanId: string
}

function useApplySubscriptionDiscount(uid: string) {
	return useMutation(['UmaNodeServer-Query','useApplySubscriptionDiscount'], async (data: IApplyDiscountData) => {
		const dataToSend = {
			country: data.country,
			preapprovalPlan: data.preapprovalPlan,
			preapprovalPlanId: data.preapprovalPlanId,
			uid
		}
		const response = await axios.patch(subscription_discount, dataToSend)
		return response  
	})
}

export default useApplySubscriptionDiscount