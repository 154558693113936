import { IBilling, countries } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosResponse } from 'axios'
import { mp_payment_url_specialist } from '@/config/endpoints'
import { errorHandler } from '@/config/ErrorBoundary'
import { useMutation } from 'react-query'
import paymentMethod from '../../../../components/Especialista/Utils/paymentMethod'
import { PaymentData } from './useMakeSpecialistAppointment'
import {
	IMercadopagoId,
	IMercadopagoIdResult,
} from '../Payments/useMercadopagoId'
import { IClientState } from '@/config/clients'
import { isPFA } from '@/utils/validations/ValidateCoverage'

export interface IHaveToPay {
	subscription: boolean
	showPrice: boolean // Es si cubre la obra social true. Si es consultorio o no cubre la OSS es false.
	uma_creditos: number
	price: IBilling | undefined
	type: 'consultorio' | 'online'
}

export interface IPaymentData {
	trueMethod: specialistPaymentMethods
	id: string
	method: 'mercadopago' | 'Subscription' | 'OSS'
	price: number
}

export interface IPaymentData_consultorios {
	trueMethod: 'consultorio'
	method: 'consultorio'
	practices: 'especialista' // ni idea honestamente
}

async function getMercadopagoId(data: IMercadopagoId) {
	let firebaseToken: string
	let transactionInfo: AxiosResponse<IMercadopagoIdResult>

	try {
		firebaseToken = await getFirebaseIdToken()
	} catch (err) {
		if (errorHandler) errorHandler.report(err as Error)
		throw new Error(`${err}`)
	}

	const headers = {
		Authorization: `Bearer ${firebaseToken}`,
		uid: data.uid,
		'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
	}

	try {
		transactionInfo = await axios.post<IMercadopagoIdResult>(
			mp_payment_url_specialist,
			{
				...data,
				country: process.env.NEXT_PUBLIC_COUNTRY as countries,
			},
			{ headers },
		)
	} catch (err) {
		if (errorHandler) errorHandler.report(err as Error)
		throw new Error(`${err}`)
	}

	return transactionInfo.data as IMercadopagoIdResult
}

export type specialistPaymentMethods =
	| 'OSS'
	| 'Subscription'
	| 'UmaCreditos'
	| 'mercadopago'
	| 'consultorio'
/**
 *
 * @param info objeto con la información para saber el método de pago
 * @param info.subscription si esta subscripto
 * @param info.showPrice Si cubre la OSS, si estas en consultorio da siempre false.
 * @param info.uma_creditos Cantidad de umacreditos
 * @param info.price Precio
 * @returns
 */
function usePaymentData(
	{ subscription, showPrice, uma_creditos, price, type }: IHaveToPay,
	data: IMercadopagoId,
	client?: IClientState
) {
	return useMutation(
		['UmaNodeServer-Query', 'paymentData'],
		async (): Promise<PaymentData> => {
			const transactionInfo =
				price && price?.price > 0
					? await getMercadopagoId(data)
					: undefined
			const truePaymentMethod = paymentMethod({
				subscription,
				showPrice,
				uma_creditos,
				price,
				type,
			})

			if (transactionInfo && truePaymentMethod === 'mercadopago') {
				return {
					trueMethod: truePaymentMethod,
					id: transactionInfo.id,
					method: truePaymentMethod,
					price: transactionInfo.price,
					initialPrice: transactionInfo.initialPrice,
				}
			}

			if (truePaymentMethod === 'UmaCreditos') {
				return {
					trueMethod: truePaymentMethod,
					id: 'UmaCreditos',
					method: 'UmaCreditos', // esta así por el back lamentablemente
					price: transactionInfo?.price ?? 0,
					initialPrice: transactionInfo?.initialPrice ?? 0,
				}
			}

			// TODO: refactor price method to OSS
			if (transactionInfo && truePaymentMethod === 'OSS') {
				return {
					trueMethod: truePaymentMethod,
					id: transactionInfo.id,
					method: 'mercadopago', // esta así por el back lamentablemente
					price: transactionInfo.price,
				}
			}

			if (truePaymentMethod === 'Subscription') {
				return {
					trueMethod: truePaymentMethod,
					id: 'Subscription',
					method: 'Subscription',
					price: 0,
					initialPrice: 0,
				}
			}

			// Si llegaste acá es porque no se pudo obtener la info de transactionInfo, para el caso de PFA se necesita agregar el siguiente error para controlar que no se pueda crear la consulta con método de pago consultorio.
			if(client && isPFA(client) && !transactionInfo) {
				errorHandler?.report(`Error al crear consulta especialista para PFA: TransactionInfo no existe - Price ${price ? price.price : undefined} - TruePaymentMethod ${truePaymentMethod}- Revisar getMercadopagoId`)
				throw new Error('Error al obtener información de transacción')
			}

			// Si llegaste acá sólo queda consultorio
			return {
				trueMethod: truePaymentMethod,
				method: 'consultorio',
				practices: 'especialista', // ni idea honestamente
			}
		},
	)
}

export default usePaymentData
